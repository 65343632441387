@tailwind base;
@tailwind components;
@tailwind utilities;

.svg-animate {
    stroke-dasharray: 200;
    animation: svg-animate 5s ease-in-out reverse;
}

@keyframes svg-animate {
    from {
        stroke-dashoffset: 000;
    }
    to {
        stroke-dashoffset: 200;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.shadow-effect {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08), 0 6px 6px rgba(0, 0, 0, 0.1),
        inset 0 2px 4px rgba(0, 0, 0, 0.04);
    transition: box-shadow 0.3s ease-in-out;
}

.shadow-effect:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.15),
        inset 0 3px 4px rgba(0, 0, 0, 0.05);
}

/* narrowly striped gradient of yellow and grey */
.combined-values-gradient {
    background: linear-gradient(
        45deg,
        yellow 20%,
        grey 20%,
        grey 40%,
        yellow 40%,
        yellow 60%,
        grey 60%,
        grey 80%,
        yellow 80%,
        yellow 100%
    );
}
