/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
    margin: 0;
}

/* set up the body */
body {
    line-height: 1.5;
    min-height: 100vh;
    overflow: hidden;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* make images easier to work with */
img,
picutre {
    max-width: 100%;
    display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
    font: inherit;
}

/* ------------------- */
/* Popup component     */
/* ------------------- */

.popup-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: gainsboro;
    position: fixed;
    width: 20vw;
    height: 20vh;
    top: 0;
    left: 0;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));
    border-radius: 5px;
}

.popup-content {
    text-align: center;
    font-size: 2rem;
    justify-content: flex-end;
}

.horizontal-spacer-popup {
    border-bottom: 1px solid black;
    margin: 0 auto;
    opacity: 60%;
    width: 95%;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
}

.popup-button-yes,
.popup-button-cancel {
    width: 20%;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.3s;
}

.popup-button-yes:hover,
.popup-button-yes:focus,
.popup-button-cancel:hover,
.popup-button-cancel:focus {
    transform: scale(1.15);
}

.popup-button-cancel {
    background-color: rgb(250, 54, 54);
    color: white;
}

.popup-button-yes {
    background-color: rgb(42, 42, 253);
    color: white;
}

/* ------------------- */
/* LandLordMain        */
/* ------------------- */

.navbar {
    width: 100vw;

    cursor: pointer;
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
}

.logout-button {
}

.edit-button {
}

.invoice-button {
}

.user-login {
}

.container-main {
    display: flex;
    width: 100%;
    height: 100%;
}

.container-left {
    width: 30%;
    height: 100vh;
    display: flex;
}

.container-right {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.vertical-spacer {
    border-right: 1.5px solid black;
    height: 88vh;
    opacity: 50%;
    align-self: center;
    margin-left: auto;
}

.tenant-list-nav {
    width: 30vw;
    display: flex;
}

.tenant-list-nav-buttons {
    display: flex;
    margin-left: auto;
    margin-right: 0.5rem;
    gap: 1rem;
}

.plus-button {
}

.filter-icon {
}

.tenant-info-heading {
    margin: 0 auto;
}

.horizontal-spacer {
    border-bottom: 1px solid black;
    align-self: center;
    opacity: 60%;
    width: 95%;
}

/* ------------------- */
/* TenantListConfig    */
/* ------------------- */

.tenant-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 90%;
    width: 50%;
    margin: auto auto;
    padding: 1rem;
}

.edit-tenant-form-buttons {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.edit-tenant-form-save-button,
.edit-tenant-form-delete-button {
    letter-spacing: 1px;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.3s;
}

.edit-tenant-form-save-button:hover,
.edit-tenant-form-save-button:focus,
.edit-tenant-form-delete-button:hover,
.edit-tenant-form-delete-button:focus {
    transform: scale(1.15);
}

.edit-tenant-form-delete-button {
    background-color: rgb(250, 54, 54);
    color: white;
}

.edit-tenant-form-save-button {
    background-color: rgb(42, 42, 253);
    color: white;
}

.stat-image {
    max-height: 20px;
}

.light .green {
    box-shadow: 0 0 20px #111 inset, 0 0 10px lime;
}

.locked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

/* Modal Animation */

@keyframes backdrop-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.backdrop-fade {
    animation: backdrop-fade-in 0.5s ease;
}

@keyframes modal-3d-fade-in {
    0% {
        opacity: 0;
        transform: perspective(750px) scale(0.5) translate3d(0px, 0px, -250px) rotateX(30deg);
    }
    100% {
        opacity: 1;
        transform: perspective(750px) scale(1) translate3d(0px, 0px, 0px) rotateX(0deg);
    }
}

.modal-fade-in {
    animation: modal-3d-fade-in 0.5s ease;
}

@keyframes blur-fade-in {
    0% {
        filter: contrast(0);
    }
    100% {
        filter: contrast(1.15);
    }
}

.blur-fade-in {
    animation: blur-fade-in 1.5s ease;
}

.focus-auth {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    mask: radial-gradient(ellipse, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 1));
    backdrop-filter: blur(10px);
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.overflow-y-auto {
    overflow-y: auto;
    scrollbar-width: thin;
}

.overflow-y-auto::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar:vertical,
::-webkit-scrollbar-track:vertical {
    width: 4px;
}

::-webkit-scrollbar-track:horizontal,
::-webkit-scrollbar:horizontal {
    height: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #888;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.noscrollbar::-webkit-scrollbar {
    display: none;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in 0.5s ease;
}

@keyframes scale-in {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.scale-in {
    transform-origin: left;
    animation: scale-in 0.75s ease;
}

@keyframes scale-in-x-y {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.scale-in-x-y {
    transform-origin: center;
    animation: scale-in-x-y 0.6s ease;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.bounce-in {
    font-size: 1.5rem;
    animation: bounce-in 0.5s ease;
    color: #047857;
}

.container-top-fader {
    background-image: linear-gradient(white 5%, transparent);
    position: absolute;
    top: 0;
    height: 2rem;
    width: 100%;
    z-index: 500;
    transform: translateY(-0.0625rem);
}

/* Toast styles */
.custom-toast {
    color: black; /* Change the color to your desired color */
}

@keyframes chaotic-3d-spin {
    0% {
        transform: perspective(750px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    100% {
        transform: perspective(750px) rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

.chaotic-3d-spin {
    animation: chaotic-3d-spin 5s infinite linear;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(6rem) scaleX(0.5) scaleY(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0rem) scaleX(1) scaleY(1);
        opacity: 1;
    }
}

.slide-in-right {
    animation: slide-in-right 0.5s ease;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-6rem) scaleX(0.5) scaleY(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0rem) scaleX(1) scaleY(1);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slide-in-left 0.5s ease;
}

@keyframes scaleX {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.scaleX {
    transform-origin: right;
    animation: scaleX 0.75s ease;
}

a {
    user-select: none;
    outline: none;
    background-color: white;
    text-decoration: none !important;
    color: inherit;
}
a:hover {
    user-select: none;
    outline: none;
    background-color: white;
    text-decoration: none !important;
    color: inherit;
}
a:focus {
    user-select: none;
    outline: none;
    background-color: white;
    text-decoration: none !important;
    color: inherit;
}
a:active {
    user-select: none;
    outline: none;
    background-color: white;
    text-decoration: none !important;
    color: inherit;
}
a:link {
    user-select: none;
    outline: none;
    background-color: white;
    text-decoration: none !important;
    color: inherit;
}

.mobile-dates {
    width: 100%;
    z-index: 50000;
}

@keyframes datepicker-slider {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.mobile-dates .react-datepicker {
    border-radius: 2rem !important;
    padding: 0.5rem;
    border: none;
    box-shadow: 0 0.5rem 1rem #04785739;
    animation: datepicker-slider 0.5s ease;
    transform-origin: top;
}

.mobile-dates .react-datepicker__header {
    position: relative;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: white;
    border-bottom: 1px solid #047857ab;
}

.mobile-dates .react-datepicker__navigation--next {
    transform: scale(1);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.mobile-dates .react-datepicker__navigation--previous {
    transform: scale(1);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

.mobile-dates .react-datepicker__day-names {
    font-weight: 900;
}

.mobile-dates .react-datepicker__current-month {
    font-size: 1rem;
    color: #047857;
}

.mobile-dates .react-datepicker__day--keyboard-selected {
    font-size: 1rem;
    transform: scale(1.2);
    color: green;
    font-weight: bold;
    background-color: white;
    color: #047857;
}

.mobile-dates .react-datepicker__day--selected {
    background-color: #047857;
    border-radius: 2rem;
}

.mobile-dates .react-datepicker__triangle::after {
    background-color: white;
}

.mobile-app ::-webkit-scrollbar {
    display: none;
}

/* Donut */

.doughnut-container-mobile {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 900px;
}

.inner-text-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 20%;
    left: 20%;
    padding: 1.25em 0;
    line-height: 120%;
    overflow: hidden;
    text-align: center;
}

.title-mobile {
    color: #047857;
    font-size: 1.5rem;
}

.info-mobile {
    color: #047857;
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.title-critical-mobile {
    color: #fbbf24;
    font-size: 1.5rem;
    margin-top: 2rem;
}

.info-critical-mobile {
    color: #fbbf24;
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

@keyframes fade-scale-in {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-fade-scale-in {
    animation: fade-scale-in 0.2s ease;
}
