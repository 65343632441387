.rw-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.rw-container-main {
    flex: 3;
}

.rw-forecast-days-panel {
    flex: 1;
}
